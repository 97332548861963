import { BrandingThemeType } from "./index";

export const languageKey = "languageKey";
export const getLanguageFromStorage = () => {
  const languageFromStorage = localStorage.getItem(languageKey);
  return languageFromStorage || "";
};

export const themeKey = "themeKey";
export const getThemeFromStorage = () => {
  const themeFromStorage = localStorage.getItem(
    themeKey
  ) as BrandingThemeType | null;
  return themeFromStorage || BrandingThemeType.light;
};
