const stripe = document.getElementById("stripe") as HTMLElement;

export const showStripe = () => (stripe.style.display = "flex");

export const addCloseStripeButton = (close: () => void) => {
  const closeButton = document.createElement("div");
  closeButton.style.position = "absolute";
  closeButton.style.top = "20px";
  closeButton.style.right = "20px";
  closeButton.style.fontWeight = "bold";
  closeButton.style.fontSize = "30px";
  closeButton.style.lineHeight = "30px";
  closeButton.innerHTML = "✕";
  closeButton.className = "clickable";
  closeButton.onclick = () => {
    stripe.style.display = "none";
    stripe.innerHTML = "";
    close();
  };
  stripe.appendChild(closeButton);
};
