import { base64Roboto, base64SF } from "./index";

export const setDocIcon = (icon: string) =>
  document.head.querySelector('link[rel="icon"]')?.setAttribute("href", icon);

export const setDocTitle = (title: string) => (document.title = title);

export const setDocThemeColor = (color: string) => {
  document.documentElement.style.setProperty("--background", color);
  document.head
    .querySelector('meta[name="theme-color"]')
    ?.setAttribute("content", color);
};

export const setDocFont = (fontFamily: string) => {
  const style = document.getElementById("font");
  if (style) {
    style.innerHTML = fontFamily === "Roboto" ? base64Roboto : base64SF;
  }
};
