import { VariableSource, VariableSourceType } from "./index";

export const currentUserVariableSource: VariableSource = {
  selector: {
    source: {
      type: VariableSourceType.globalVariable,
      variableName: "currentUserId",
    },
  },
  collection: { name: "profiles" },
  type: VariableSourceType.collection,
};
