import { TableQueryFilterOperand } from "./index";

export const operators = {
  [TableQueryFilterOperand.equal]: "==",
  [TableQueryFilterOperand.notEqual]: "!=",
  [TableQueryFilterOperand.less]: "<",
  [TableQueryFilterOperand.lessOrEqual]: "<=",
  [TableQueryFilterOperand.greater]: ">",
  [TableQueryFilterOperand.greaterOrEqual]: ">=",
  [TableQueryFilterOperand.contains]: "array-contains",
};

export const needOrderByOperators = [
  operators[TableQueryFilterOperand.notEqual],
  operators[TableQueryFilterOperand.less],
  operators[TableQueryFilterOperand.lessOrEqual],
  operators[TableQueryFilterOperand.greater],
  operators[TableQueryFilterOperand.greaterOrEqual],
];
