import { GetVariableValue } from "../hooks";
import {
  BooleanVariable,
  StringVariable,
  progressActiveAttribute,
  progressIndicatorAttribute,
  progressPassedAttribute,
} from "./index";

export const carouselHandler = async (
  carousel: HTMLElement,
  getVariableValue: GetVariableValue,
  value?: StringVariable,
  duration?: number,
  loop?: BooleanVariable
) => {
  const slides = Array.from(
    carousel.querySelectorAll(":scope > div")
  ) as HTMLElement[];
  const progressParts = Array.from(
    carousel.querySelectorAll(
      `:scope > i > svg > rect[${progressIndicatorAttribute}]`
    )
  ) as SVGRectElement[];
  if (slides.length && progressParts.length) {
    let timer: NodeJS.Timeout | null;
    const lastIndex = slides.length - 1;
    const infinite = loop?.constant;
    slides.forEach(
      (el, i) =>
        (el.onclick = (e) => {
          if (timer) {
            clearTimeout(timer);
          }
          slides.slice(0, i).forEach((el) => (el.hidden = true));
          slides.slice(i + 1).forEach((el) => (el.hidden = true));
          slides[i].removeAttribute("hidden");
          progressParts.slice(0, i).forEach((el) => {
            el.setAttribute(progressPassedAttribute, "");
            el.removeAttribute(progressActiveAttribute);
          });
          progressParts.slice(i + 1).forEach((el) => {
            el.removeAttribute(progressPassedAttribute);
            el.removeAttribute(progressActiveAttribute);
          });
          progressParts[i].removeAttribute(progressPassedAttribute);
          progressParts[i].setAttribute(progressActiveAttribute, "");
          const prev = () => {
            if (slides[i - 1]) {
              slides[i - 1].click();
            } else if (infinite) {
              slides[lastIndex].click();
            }
          };
          const next = () => {
            if (slides[i + 1]) {
              slides[i + 1].click();
            } else if (infinite) {
              slides[0].click();
            }
          };
          if (e.offsetX) {
            if (e.offsetX < slides[i].clientWidth / 5) {
              prev();
            } else {
              next();
            }
          } else {
            if (!document.getElementById(carousel.id)) {
              return;
            } else {
              timer = setTimeout(next, (duration || 5) * 1000);
            }
          }
        })
    );
    const valueValue = value?.source
      ? await getVariableValue(value.source)
      : value?.constant;
    const currentValue = valueValue ? +valueValue : 0;
    slides[currentValue > lastIndex ? 0 : currentValue].click();
  }
};
