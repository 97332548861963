export const checkNotificationsEnabled = () =>
  "Notification" in window && Notification.permission === "granted";

export const enableNotifications = async () => {
  if ("Notification" in window) {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      new Notification("Enabled");
      return;
    }
  }
  throw new Error(
    "User Notifications Permission Denied. Enable Notifications in browser settings."
  );
};

const accepted_cookies = "accepted_cookies";
export const checkIdfaAndCookieTrackingEnabled = () =>
  document.cookie.includes(accepted_cookies);

export const enableIdfaAndCookieTracking = () => {
  if (navigator.cookieEnabled) {
    document.cookie = `${accepted_cookies}=yes;`;
    return;
  }
  throw new Error(
    "User Cookies Permission Denied. Enable Cookies in browser settings."
  );
};
