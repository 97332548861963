import { useEffect, useState } from "react";

const currentVersion = "currentVersion";

export const useVersionController = () => {
  const [timer, setTimer] = useState<NodeJS.Timeout>();
  const [newVersion, setNewVersion] = useState("");

  const getVersion = () =>
    fetch("/version.json")
      .then((response) => response.json())
      .then((json) => {
        const oldVersion = localStorage.getItem(currentVersion);
        if (!oldVersion) {
          localStorage.setItem(currentVersion, json.currentVersion);
        } else if (oldVersion !== json.currentVersion) {
          setNewVersion(json.currentVersion);
          clearInterval(timer);
        }
      });

  useEffect(() => {
    if (!window.location.href.includes("http://localhost")) {
      getVersion();
      setTimer(
        setInterval(() => {
          getVersion();
        }, 60000)
      );
    }
    return () => {
      clearInterval(timer);
    };
  }, []);

  const reload = () => {
    localStorage.setItem(currentVersion, newVersion);
    // @ts-ignore
    window.location.reload(true);
  };

  useEffect(() => {
    if (!!newVersion) {
      if (window.confirm(`New version available, refresh to update?`)) {
        reload();
        setNewVersion("");
      }
    }
  }, [newVersion]);
};
