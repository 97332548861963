export const createLogo = (
  screen: HTMLElement,
  applicationName: string,
  applicationIcon: string,
  color: string,
  fontFamily: string,
  hasText: boolean,
  goHome?: () => void
) => {
  const logo = document.createElement("div");
  logo.id = "logo";
  screen.appendChild(logo);

  const imageElement = document.createElement("img");
  imageElement.setAttribute("src", applicationIcon);
  logo.appendChild(imageElement);

  if (hasText) {
    const textElement = document.createElement("div");
    textElement.style.color = color;
    textElement.style.fontFamily = fontFamily;
    textElement.innerHTML = applicationName;
    logo.appendChild(textElement);
  }

  if (goHome) {
    logo.className = "clickable";
    logo.onclick = () => goHome();
  }
};
