import { useEffect, useState } from "react";

import { Page } from "./Page";
import {
  BrandingThemeType,
  Config,
  Screen,
  changeThemeEvent,
  setDocFont,
  setDocIcon,
  setDocThemeColor,
  setDocTitle,
  setLoaderIndicatorColor,
} from "./utils";

interface Props {
  inDevice: boolean;
  language: string;
  setLanguage: React.Dispatch<React.SetStateAction<string>>;
  theme: BrandingThemeType;
  setTheme: React.Dispatch<React.SetStateAction<BrandingThemeType>>;
  config: Config;
  email?: string;
  token?: string;
}

export const App: React.FC<Props> = ({
  inDevice,
  language,
  setLanguage,
  theme,
  setTheme,
  config,
  email,
  token,
}) => {
  const {
    localization: { languages = [], primaryLanguage },
    branding: {
      appName,
      icons: { iosIcon },
      colorStyles,
      typography: { iosDefaultFontFamily: fontFamily },
    },
    resources = [],
    screens = [],
  } = config;
  const applicationName = appName.locales[language] || "";
  const applicationIcon = resources.find((el) => el.id === iosIcon)?.url || "";
  const launchScreen = screens.find(
    (el) => el.screenName === "Launch Screen"
  ) as Screen;
  const paywallScreen = screens.find(
    (el) => el.screenName === "Purchases"
  ) as Screen;

  const [desktopMode, setDesktopMode] = useState(window.innerWidth >= 680);
  const [wideDesktopMode, setWideDesktopMode] = useState(
    window.innerWidth >= 1032
  );

  useEffect(() => {
    setDocIcon(applicationIcon);
    setDocFont(fontFamily);
    if (email || token) {
      config.tabBars = [];
    }
    if (!languages.includes(language)) {
      setLanguage(primaryLanguage);
    }
    const resize = () => {
      setDesktopMode(window.innerWidth >= 680);
      setWideDesktopMode(window.innerWidth >= 1032);
    };
    window.addEventListener("resize", resize);
    const detectThemeChangeEvent = (e: any) =>
      setTheme(e.detail ? BrandingThemeType.dark : BrandingThemeType.light);
    document.addEventListener(changeThemeEvent, detectThemeChangeEvent);
    return () => {
      window.removeEventListener("resize", resize);
      document.removeEventListener(changeThemeEvent, detectThemeChangeEvent);
    };
  }, []);

  useEffect(() => {
    setDocTitle(applicationName);
  }, [applicationName]);

  useEffect(() => {
    setLoaderIndicatorColor(colorStyles.primary[theme]);
    setDocThemeColor(colorStyles.inverseOnSurface[theme]);
  }, [theme]);

  return (
    <Page
      inDevice={inDevice}
      language={language}
      theme={theme}
      desktopMode={desktopMode}
      wideDesktopMode={wideDesktopMode}
      config={config}
      screens={screens}
      launchScreen={launchScreen}
      paywallScreen={paywallScreen}
      email={email}
      token={token}
    />
  );
};
