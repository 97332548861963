import { GetVariableValue, Refresh } from "../hooks";
import {
  BrandingColorStyles,
  BrandingColorType,
  BrandingThemeType,
  ComponentType,
  CoordinateConstant,
  InsetsConstant,
  LocalizedContent,
  MediaVariable,
  Resource,
  Screen,
  ScreenComponent,
  StringVariable,
  TextAlignment,
  VariableSource,
} from "./index";

export const tabBarHeight = 49;
export const topBarHeight = 44;
export const safeAreaTop = 20;
export const safeAreaBottom = 0;

export enum BarComponentType {
  tabBar = "tabBar",
  tab = "tab",
  topBar = "topBar",
}

export const getComponentTypeById = (id: string) =>
  id.split(".")[0] as ComponentType | BarComponentType;

export const isParentComponent = (
  componentType: ComponentType | BarComponentType
) =>
  componentType === ComponentType.overlay ||
  componentType === ComponentType.scroll ||
  componentType === ComponentType.stack ||
  componentType === ComponentType.carousel ||
  componentType === ComponentType.list;

export const getRealWidth = (
  getPxValue: (val?: string) => string,
  margins?: InsetsConstant
) =>
  `calc(100% - (${getPxValue(margins?.leading)}px + ${getPxValue(
    margins?.trailing
  )}px))`;

export const getRealHeight = (
  getPxValue: (val?: string) => string,
  margins?: InsetsConstant
) =>
  `calc(100% - (${getPxValue(margins?.top)}px + ${getPxValue(
    margins?.bottom
  )}px))`;

export enum ISize {
  buttonHeight = "@buttonHeight",
  cornersRadius = "@cornersRadius",
  max = "@max",
  topScreenMargins = "@topScreenMargins",
  trailingScreenMargins = "@trailingScreenMargins",
  bottomScreenMargins = "@bottomScreenMargins",
  leadingScreenMargins = "@leadingScreenMargins",
  thinSeparator = "@thinSeparator",
}

export const getPx =
  (
    hasTabBar: boolean,
    hasTopBar: boolean,
    safeAreaTop: number,
    safeAreaBottom: number
  ) =>
  (value?: string) => {
    switch (value) {
      case ISize.thinSeparator:
        return "1";
      case ISize.buttonHeight:
        return "50";
      case ISize.cornersRadius:
        return "8";
      case ISize.topScreenMargins:
        return `${safeAreaTop + (hasTopBar ? topBarHeight : 0)}`;
      case ISize.trailingScreenMargins:
        return "0";
      case ISize.bottomScreenMargins:
        return `${safeAreaBottom + (hasTabBar ? tabBarHeight : 0)}`;
      case ISize.leadingScreenMargins:
        return "0";
      case undefined:
        return "0";
      case "":
        return "0";
      default:
        return value;
    }
  };

export enum TextAlign {
  START = "start",
  CENTER = "center",
  END = "end",
}

export const getCSSTextAlignment = (val?: TextAlignment) => {
  switch (val) {
    case TextAlignment.leading:
      return TextAlign.START;
    case TextAlignment.center:
      return TextAlign.CENTER;
    case TextAlignment.trailing:
      return TextAlign.END;
    default:
      return TextAlign.START;
  }
};

export const getText = (language: string, text?: LocalizedContent) => {
  let key = "key";
  let value = "";
  let hasLocale = false;
  if (text) {
    key = text.key;
    hasLocale = text.locales[language] !== undefined;
    if (hasLocale) {
      value = text.locales[language];
    } else {
      value = key;
    }
  }
  return { key, value, hasLocale };
};

export const getTextValue = async (
  language: string,
  getVariableValue: GetVariableValue,
  refresh?: Refresh,
  text?: LocalizedContent
) => {
  let { value, hasLocale } = getText(language, text);
  if (hasLocale && text) {
    const { values } = text;
    if (values) {
      for (let i = 0; i < values.length; i++) {
        const index = i + 1;
        value = value.replace(
          `$${index}`,
          await getVariableValue(values[i], refresh)
        );
      }
    }
  }
  return value;
};

const cloudinaryUrl = "https://res.cloudinary.com/codeplatform/image/upload";

export const getMediaResourceUrl = async (
  resources: Resource[],
  minWidth: number,
  getVariableValue: GetVariableValue,
  refresh?: Refresh,
  media?: MediaVariable,
  imageColor?: StringVariable,
  theme?: BrandingThemeType,
  colorStyles?: BrandingColorStyles
) => {
  const getUrl = async (source: VariableSource): Promise<string> => {
    const variableValue = await getVariableValue(source, refresh);
    try {
      return JSON.parse(variableValue).url;
    } catch {
      return variableValue;
    }
  };
  const mediaResource = media?.source
    ? {
        url: await getUrl(media.source),
        width: 0,
      }
    : resources.find((el) => el.id === media?.constant?.resourceId);
  let url = mediaResource?.url || "";
  if (url.startsWith(cloudinaryUrl)) {
    url = url.replace(".svg", ".png");
    if (imageColor && colorStyles && theme) {
      const color = await getColor(
        imageColor,
        colorStyles,
        theme,
        getVariableValue,
        refresh
      );
      url = url.replace(
        cloudinaryUrl,
        `${cloudinaryUrl}/e_colorize,co_rgb:${color.slice(1)}`
      );
    }
    if (minWidth) {
      const urlParts = url.split("/");
      const crop = urlParts.find((el) => el.includes("c_crop"));
      if (crop) {
        const cropIndex = urlParts.indexOf(crop);
        const cropParts = crop.split(",");
        const width = cropParts[2]?.replace("w_", "");
        const scale = Math.ceil(minWidth / +width);
        if (scale > 1) {
          urlParts.splice(cropIndex + 1, 0, `w_${scale}.0,c_scale`);
          url = urlParts.join("/");
        }
      } else if (mediaResource?.width) {
        const scale = Math.ceil(minWidth / mediaResource.width);
        if (scale > 1) {
          url = url.replace(
            cloudinaryUrl,
            `${cloudinaryUrl}/w_${scale}.0,c_scale`
          );
        }
      }
    }
  }
  return url;
};

export const getColor = async (
  color: StringVariable,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  getVariableValue: GetVariableValue,
  refresh?: Refresh
) =>
  colorStyles[
    (color.source
      ? await getVariableValue(color.source, refresh)
      : (color.constant as string)
    ).slice(1) as BrandingColorType
  ][theme];

export const findComponent = (
  screenConfig: Screen,
  propName: keyof ScreenComponent,
  value: string
): ScreenComponent | undefined => {
  const { components, topBar } = screenConfig;
  const find = (components: ScreenComponent[]): ScreenComponent | undefined => {
    let component: ScreenComponent | undefined;
    for (const el of components) {
      if (el[propName] === value) {
        component = el;
      } else if (el.subComponents) {
        component = find(el.subComponents);
      }
      if (component) {
        return component;
      }
    }
  };
  return find([
    ...(components || []),
    ...(topBar?.leadingComponents || []),
    ...(topBar?.headlineComponents || []),
    ...(topBar?.trailingComponents || []),
  ]);
};

export const getElementIdFromConfig = (component: ScreenComponent) =>
  `${component.componentType}.${component.name || component.componentType}.${
    component.id
  }`;

export const coordinateHandler = (
  coordinate: CoordinateConstant,
  center: { lat: number; lng: number }
) => {
  const lat = coordinate.latitude;
  const lng = coordinate.longitude;
  if (lat) {
    center.lat = +lat;
  }
  if (lng) {
    center.lng = +lng;
  }
  return center;
};

export const readableError = (err: string) =>
  err.split(" (")[0]?.replace("Firebase: ", "");

export const getInputParameters = (screenConfig?: Screen) => {
  const inputParameter = screenConfig?.inputParameters?.find(
    (el) => el.required
  );
  const searchInputParameters = screenConfig?.inputParameters?.filter(
    (el) => !el.required
  );
  const inoutInputParameters = screenConfig?.inputParameters?.filter(
    (el) => el.inout
  );
  return { inputParameter, searchInputParameters, inoutInputParameters };
};

export const getSearch = (params: { [key: string]: string }) => {
  const search = Object.keys(params)
    .filter((el) => !!params[el])
    .map((el) => `${el}=${encodeURIComponent(params[el])}`)
    .join("&");
  return search ? `?${search}` : undefined;
};
