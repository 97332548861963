const loader = document.getElementById("loader") as HTMLElement;
const loaderIndicator = document.getElementById(
  "loaderIndicator"
) as HTMLElement;

export const setLoaderIndicatorColor = (color: string) =>
  (loaderIndicator.style.background = color);

export const startLoader = () => (loader.style.display = "block");

export const stopLoader = () => (loader.style.display = "none");
