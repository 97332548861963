import { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import slugify from "react-slugify";

import { FirebaseContext } from "./context";
import { setInputParameters, useVariable } from "./hooks";
import {
  BrandingThemeType,
  Config,
  Screen,
  VariableSourceType,
  getInputParameters,
  getScreen,
  getSearch,
} from "./utils";

export const rootElement = document.getElementById("root") as HTMLElement;

interface Props {
  inDevice: boolean;
  language: string;
  theme: BrandingThemeType;
  desktopMode: boolean;
  wideDesktopMode: boolean;
  config: Config;
  screens: Screen[];
  launchScreen: Screen;
  paywallScreen: Screen;
  email?: string;
  token?: string;
}

export const Page: React.FC<Props> = ({
  inDevice,
  language,
  theme,
  desktopMode,
  wideDesktopMode,
  config,
  screens,
  launchScreen,
  paywallScreen,
  email,
  token,
}) => {
  const { f } = useContext(FirebaseContext);

  const go = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const [launchOpen, setLaunchOpen] = useState(true);
  const [paywallOpen, setPaywallOpen] = useState(false);

  const pages = pathname.slice(1).split("/-/");
  const [page, encodedInputParameterValue] = pages[pages.length - 1].split("/");
  const inputParameterValue = encodedInputParameterValue
    ? decodeURIComponent(encodedInputParameterValue)
    : undefined;

  const originalScreen = useMemo(
    () => screens.find((el) => slugify(el.screenName) === page),
    [page]
  );

  const screen = useMemo(
    () =>
      launchOpen ? launchScreen : paywallOpen ? paywallScreen : originalScreen,
    [launchOpen, paywallOpen, originalScreen]
  );

  const leadingPaneScreenName = screen?.experimental?.leadingPane?.screenName;
  const leadingPaneScreen = useMemo(
    () =>
      wideDesktopMode &&
      screens.find((el) => el.screenName === leadingPaneScreenName),
    [wideDesktopMode, leadingPaneScreenName]
  );

  const trailingPaneScreenName = screen?.experimental?.trailingPane?.screenName;
  const trailingPaneScreen = useMemo(
    () =>
      wideDesktopMode &&
      screens.find((el) => el.screenName === trailingPaneScreenName),
    [wideDesktopMode, trailingPaneScreenName]
  );

  const { searchInputParameters, inoutInputParameters } =
    getInputParameters(screen);

  const { getVariable, update } = useVariable(
    f,
    config,
    language,
    screen,
    inputParameterValue
  );

  const navigate = (pathname: string, search?: string) => {
    setPaywallOpen(false);
    go({ pathname, search });
  };

  const navigateBack = (screenConfig: Screen) => async () => {
    const pathname = `/${pages.slice(0, -1).join("/-/")}`;
    const params: { [key: string]: string } = {};
    if (inoutInputParameters) {
      for (const el of inoutInputParameters) {
        params[el.parameter] = await getVariable(screenConfig)({
          type: VariableSourceType.localVariable,
          variableName: el.parameter,
        });
      }
    }
    navigate(pathname, getSearch(params));
  };

  const generateScreen = async () => {
    if (leadingPaneScreen) {
      await getScreen(
        inDevice,
        f,
        leadingPaneScreen,
        config,
        rootElement,
        desktopMode,
        language,
        theme,
        launchOpen,
        setLaunchOpen,
        searchParams,
        setSearchParams,
        (pathname, search) =>
          navigate(
            pathname.startsWith("/")
              ? pathname
              : `/${pages.slice(0, -1).join("/-/")}/${pathname}`,
            search
          ),
        navigateBack(leadingPaneScreen),
        setPaywallOpen,
        getVariable,
        update,
        email,
        token,
        true
      );
    }
    if (screen) {
      await getScreen(
        inDevice,
        f,
        screen,
        config,
        rootElement,
        desktopMode,
        language,
        theme,
        launchOpen,
        setLaunchOpen,
        searchParams,
        setSearchParams,
        navigate,
        navigateBack(screen),
        setPaywallOpen,
        getVariable,
        update,
        email,
        token,
        !!trailingPaneScreen,
        !!leadingPaneScreen,
        inputParameterValue
      );
    } else {
      // 404
    }
    if (trailingPaneScreen) {
      await getScreen(
        inDevice,
        f,
        trailingPaneScreen,
        config,
        rootElement,
        desktopMode,
        language,
        theme,
        launchOpen,
        setLaunchOpen,
        searchParams,
        setSearchParams,
        navigate,
        navigateBack(trailingPaneScreen),
        setPaywallOpen,
        getVariable,
        update,
        email,
        token,
        false,
        true
      );
    }
  };

  useEffect(() => {
    generateScreen();
  }, [
    inDevice,
    leadingPaneScreen,
    screen,
    trailingPaneScreen,
    inputParameterValue,
    language,
    theme,
    desktopMode,
  ]);

  useEffect(() => {
    if (screen && searchInputParameters) {
      setInputParameters(
        getVariable(screen),
        searchInputParameters,
        searchParams
      );
    }
  }, [screen, searchInputParameters, searchParams]);

  return <></>;
};
