import { GetVariableValue } from "../hooks";
import {
  BackgroundGradient,
  BackgroundGradientDirection,
  BrandingColorStyles,
  BrandingThemeType,
  MediaConstant,
  Resource,
  StringVariable,
  getColor,
} from "./index";

export const setColors = async (
  element: HTMLElement,
  colorStyles: BrandingColorStyles,
  theme: BrandingThemeType,
  resources: Resource[],
  getVariableValue: GetVariableValue,
  backgroundColor?: StringVariable,
  backgroundGradient?: BackgroundGradient,
  backgroundPattern?: MediaConstant,
  textColor?: StringVariable,
  borderColor?: StringVariable,
  mouseEvent?: boolean,
  hover?: boolean
) => {
  if (backgroundColor) {
    const refresh = async () => {
      const color = await getColor(
        backgroundColor,
        colorStyles,
        theme,
        getVariableValue,
        mouseEvent ? undefined : refresh
      );
      element.style.backgroundColor = hover ? `${color}cc` : color;
    };
    await refresh();
  }

  if (backgroundGradient) {
    const { direction, start, end } = backgroundGradient;
    const startColor = await getColor(
      { constant: start },
      colorStyles,
      theme,
      getVariableValue
    );
    const endColor = await getColor(
      { constant: end },
      colorStyles,
      theme,
      getVariableValue
    );
    element.style.backgroundImage =
      direction === BackgroundGradientDirection.radial
        ? `radial-gradient(${startColor}, ${endColor})`
        : `linear-gradient(to ${
            direction === BackgroundGradientDirection.vertical
              ? "bottom"
              : "right"
          }, ${startColor}, ${endColor})`;
  }

  if (backgroundPattern) {
    const url =
      resources.find((el) => el.id === backgroundPattern.resourceId)?.url || "";
    element.style.backgroundImage = `url(${url})`;
    element.style.backgroundPosition = "center";
    element.style.backgroundSize = "cover";
  }

  if (textColor) {
    const refresh = async () => {
      const color = await getColor(
        textColor,
        colorStyles,
        theme,
        getVariableValue,
        mouseEvent ? undefined : refresh
      );
      element.style.color = hover ? `${color}cc` : color;
    };
    await refresh();
  }

  if (borderColor) {
    const refresh = async () => {
      const color = await getColor(
        borderColor,
        colorStyles,
        theme,
        getVariableValue,
        mouseEvent ? undefined : refresh
      );
      element.style.borderColor = hover ? `${color}cc` : color;
    };
    await refresh();
  }
};
