import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/storage";

import { getParams, GetVariableValue } from "../hooks";
import {
  generateFirestoreId,
  readableError,
  VariableSourceType,
  VariableTransformTransform,
} from "../utils";
import { FF, FirebaseContext } from "./index";

export interface FirebaseConfig {
  [key: string]: string;
}

interface Props {
  children: React.ReactNode;
  webFirebaseConfig?: FirebaseConfig;
  firestorePrefix?: string;
}

export const FirebaseProvider: React.FC<Props> = ({
  children,
  webFirebaseConfig,
  firestorePrefix = "",
}) => {
  const emailForSignInKey = "emailForSignIn";

  const firebaseConfig = webFirebaseConfig || {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
  };

  const app = firebase.initializeApp(firebaseConfig);

  const auth = firebase.auth();

  const firestore = firebase.firestore(app);

  const storage = firebase.storage(app);

  const googleProvider = new firebase.auth.GoogleAuthProvider();
  googleProvider.setCustomParameters({ prompt: "select_account" });

  const signInWithGoogle = async () => {
    await auth.signInWithPopup(googleProvider);
  };

  const createUserWithEmailAndPassword: FF = async (...props) => {
    const { email = "", password = "" } = await getParams(...props);
    await auth.createUserWithEmailAndPassword(email, password);
  };

  const signInWithEmailAndPassword: FF = async (...props) => {
    const { email = "", password = "" } = await getParams(...props);
    await auth.signInWithEmailAndPassword(email, password);
  };

  const sendPasswordResetEmail: FF = async (...props) => {
    const { email = "" } = await getParams(...props);
    await auth
      .sendPasswordResetEmail(email)
      .then(() => alert(`Link was sent to ${email}`));
  };

  const sendSignInLinkToEmail: FF = async (...props) => {
    const { email = "" } = await getParams(...props);
    await auth
      .sendSignInLinkToEmail(email, {
        url: window.location.origin,
        handleCodeInApp: true,
      })
      .then(() => localStorage.setItem(emailForSignInKey, email));
  };

  const reSendSignInLinkToEmail: FF = async (...props) => {
    const result = window.confirm("Trouble getting email? Resend?");
    if (result) {
      await sendSignInLinkToEmail(...props);
    }
  };

  const waitProfile = async (getVariableValue: GetVariableValue) => {
    const record = {
      collection: { name: "profiles" },
      selector: {
        source: {
          type: VariableSourceType.globalVariable,
          variableName: "currentUserId",
        },
      },
      transforms: [{ transform: VariableTransformTransform.exists }],
      type: VariableSourceType.collection,
    };
    const check = async () => (await getVariableValue(record)) === "true";
    let ready = await check();
    while (!ready) {
      await new Promise((r) => setTimeout(r, 1000));
      ready = await check();
    }
  };

  const getAssetRecord = (file: any) =>
    new Promise(async (resolve) => {
      const { name, type } = file;
      const [resourceType, format] = type.split("/");
      const storageRef = storage.ref(
        `uploads/${auth.currentUser?.uid}/${generateFirestoreId()}/${name}`
      );
      storageRef
        .put(file)
        .then(() =>
          storageRef.getDownloadURL().then((url) =>
            resolve({
              title: null,
              folder: "~",
              url,
              resourceType,
              format,
              width: null,
              height: null,
              bytes: null,
              duration: null,
              version: null,
              signature: null,
              error: null,
            })
          )
        )
        .catch((err) => alert(readableError(err.message)))
        .finally(() => resolve(null));
    });

  return (
    <FirebaseContext.Provider
      value={{
        f: {
          firestorePrefix,
          auth,
          firestore,
          signInWithGoogle,
          createUserWithEmailAndPassword,
          signInWithEmailAndPassword,
          sendPasswordResetEmail,
          sendSignInLinkToEmail,
          reSendSignInLinkToEmail,
          waitProfile,
          getAssetRecord,
          emailForSignInKey,
        },
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};
